import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/apps/web/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieProvider"] */ "/app/apps/web/src/lib/context/cookie-context.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./fonts/MMC-Regular.woff2\",\"weight\":\"400\"},{\"path\":\"./fonts/MMC-Medium.woff2\",\"weight\":\"500\"},{\"path\":\"./fonts/MMC-Bold.woff2\",\"weight\":\"700\"}]}],\"variableName\":\"FontMMC\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/ui/components/Breadcrumbs/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/ui/components/CartOverlay/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/ui/components/CookieNotification/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/ui/components/Header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/ui/components/SnackBar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/ui/components/WebsiteScripts/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/styles/global.css");
